#RecipeItemList{
    padding-bottom: 20px;
    margin-right: 6%;
    margin-left: 6%;
}

.container-height {
    height: 75vh;
}

.list-scroll {
    overflow-y: scroll;
}
