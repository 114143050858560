  .container {
    position: relative;
  }
  
  .backgroundbox {
    filter: blur(2px);
  }
  
  .foregroundbox {
    filter: blur(0);
  }
  
  .overlay {
    z-index: 9;
    margin: 30px;
    width:60%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
  }

  .title {
    background-color: #212529;
    color: #FFFFFF;
  }

