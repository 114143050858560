 /* The side navigation menu */
 #sideMenu {
    margin: 0;
    padding: 0;
    width: 300px;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    overflow: auto;
    transition: 0.5s;
    translate: 300px
  }
  
  /* Sidebar links */
  #sideMenu a {
    display: block;
    color: white;
    padding: 16px;
    text-decoration: none;
    display: block;
    transition: 0.3s;
  }
  
  /* Active/current link */
  #sideMenu a.active {
    background-color: #04AA6D;
    color: white;
  }
  
  /* Links on mouse-over */
  #sideMenu a:hover:not(.active) {
    background-color: #555;
    color: white;
  }
  
  #navButton{
    translate: 30px;
    z-index: 5;   
  }

  
  #navMain {
    transition: margin-right 0.5s;
    padding: 20px;
  }

  @media screen and (max-height: 450px) {
    .sidebar {padding-top: 15px;}
    .sidebar a {font-size: 18px;}
  }

ul{
  padding-top: 0;
}

#active{
  z-index: 2;
}

#transitionBlock{
  z-index: 4;
  padding-top: 20px;
  padding-bottom: 18px;
  padding-right: 50px;
  background-color: #212529;
  float: inline-end;
}

#blockContainer{
  translate: 10px;
  z-index: 3;
}